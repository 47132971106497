/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        // var scene = $('#screen-container').get(0);
        // var parallax = new Parallax(scene,{
        //   hoverOnly: true,
        //   relativeInput: true
        // });

        restartJs();

        function restartJs() {
          // Get the .gif images from the "data-alt".
          var getGif = function () {
            var gif = [];
            $('img').each(function () {
              var data = $(this).data('alt');
              gif.push(data);
            });
            return gif;
          };

          var gif = getGif();

          // Preload all the gif images.
          var image = [];

          $.each(gif, function (index) {
            image[index] = new Image();
            image[index].src = gif[index];
          });

          // Change the image to .gif when clicked and vice versa.
          $('.triggerOnEndAudio').on('click', function () {

            $('.darker').addClass("active");

            var $this = $(this).find('figure'),
              $index = $this.index(),

              $img = $this.children('img'),
              $imgSrc = $img.attr('src'),
              $imgAlt = $img.attr('data-alt'),
              $imgExt = $imgAlt.split('.');

            if ($imgExt[1] === 'gif') {
              $img.attr('src', $img.data('alt')).attr('data-alt', $imgSrc);
            } else {
              $img.attr('src', $imgAlt).attr('data-alt', $img.data('alt'));
            }

            // Add play class to help with the styling.
            $this.toggleClass('play');
          });

          $('#bal').click(function () {
            var $this = $(this);
            $this.addClass('jump');

            setTimeout(function () {
              $this.removeClass('jump');
            }, 700);
          });

          $('.navigation-element a').hover(function () {
            $(this).parent().addClass('active');
            $(this).closest('.navigation-container').addClass('active');
            $('.view, #woezel, #pip, #biebtree').addClass('blurred');
          }, function () {
            $(this).parent().removeClass('active');
            $(this).closest('.navigation-container').removeClass('active');
            $('.view, #woezel, #pip, #biebtree').removeClass('blurred');
          });

          $('#sound').click(function () {
            $(this).toggleClass('muted');

            var audio = $('audio');

            if($(this).hasClass('muted')){
              audio.each(function(i,audio){
                audio.volume = 0;
              });
            } else {
              audio.each(function(i,audio){
                audio.volume = 1;
              });
            }
          });

          $('*[data-toggle="overlay"]').on('click', function () {
            var overlayTarget = $($(this).data('target'));
            overlayTarget.addClass('active');

            // parallax.disable();
          });

          $('.overlay button[data-toggle="close"]').on('click', function () {
            var overlayTarget = $($(this).data('target'));
            overlayTarget.removeClass('active');

            // parallax.enable();
          });

          // AUDIO
          if($('#preloading').length) {
            $('#preloading').ready(function(){
              var audio = document.getElementById("audio-intro");
              setTimeout(function(){
                audio.play();
                audio.onended = function() {
                  $('.triggerOnEndAudio').trigger('click');
                };
              }, 1500);
            });
          }

          if($('#screen1').length && $('#screen1').ready()) {
            document.getElementById("audio-bg").play();
            setTimeout(function(){
              document.getElementById("audio-varen").play();

              $('.hulp button').on('click', function(e){
                document.getElementById("audio-hulp").play();

                $('#boek, #boeken').addClass('attention');

                setTimeout(function(){
                  $('.hidden-element').removeClass('attention');
                }, 3000);
              });
            }, 2500);
          }
          var canClick = true;
          $('#vlinder-hover').on('click', function(){
            if(canClick) {
              canClick = false;
              $('#vlinder').addClass('fly');

              setTimeout(function(){
                $('#vlinder').removeClass('fly');
                canClick = true;
              },5000);
            }
          });

          if($('#screen2').length && $('#screen2').ready()) {
            document.getElementById("audio-bg").play();

            setTimeout(function(){
              var audio = document.getElementById("audio-regenboog-start");
              audio.play();

              audio.onended = function() {
                $('.my-swatch').mouseenter(function(){
                  var $item = $(this).data('cursor');
                  document.getElementById("audio-" + $item ).play();
                });
              };
            }, 2500);

            $('.hulp button').on('click', function(e){
              document.getElementById("audio-hulp").play();
              var items = $('.hidden-element');


              items.each(function(i, item){

                setTimeout(function(){
                  $(item).addClass('attention');
                }, i * 500);

                setTimeout(function(){
                  $(item).removeClass('attention');
                }, i * 500 + 500);
              });
            });

            // REGENBOOG CHECKER
            var answers = [];
            var correct = ['color-red','color-yellow','color-green','color-blue','color-purple'];

            document.ondrop = function(event) {
              answers[$(event.target).index()] = event.dataTransfer.getData("text");

              if(answers.toString() == correct.toString()) {
                document.getElementById("audio-regenboog-finish").play();
                $('#success-overlay').addClass('active');

                $('body').on('click', function(e){
                  if( $('#success-overlay').hasClass('active') ) {
                    $('#success-overlay').addClass('fadeout').removeClass('active');

                    setTimeout( function(){
                      $('#success-overlay').removeClass('fadeout');
                    }, 500);
                  }
                });
              }
            };
          }

          if($('#screen3').length && $('#screen3').ready()) {
            document.getElementById("audio-bg").play();
            setTimeout(function(){
              var audio = document.getElementById("audio-intro");
              audio.play();

              audio.onended = function() {
                $('.my-items').mouseenter(function(){
                  document.getElementById("audio-" + $(this).attr('id') ).play();
                });

                var items = $('.hidden-element');
                $('.hulp button').on('click', function(e){
                  document.getElementById("audio-hulp").play();

                  items = $('.hidden-element');

                  items.each(function(i, item){

                    setTimeout(function(){
                      $(item).addClass('attention');
                    }, i * 500);

                    setTimeout(function(){
                      $(item).removeClass('attention');
                    }, i * 500 + 500);
                  });
                });
              };
            }, 1500);
          }

          if($('#screen4').length) {
            document.getElementById("audio-screen4").play();
          }
        }

        var $page = $('main'),
          options = {
            debug: true,
            prefetch: true,
            cacheLength: 2,
            forms: 'form',
            onStart: {
              duration: 1500, // Duration of our animation
              render: function ($container) {
                // Add your CSS animation reversing class
                $('body').addClass('is-exiting');
              }
            },
            onReady: {
              duration: 0,
              render: function ($container, $newContent) {
                // Remove your CSS animation reversing class
                $('body').removeClass('is-exiting');
                // Inject the new content
                $container.html($newContent);
                // Restart your js
                restartJs();
              }
            }
          },
          smoothState = $page.smoothState(options).data('smoothState');
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
